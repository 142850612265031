import './App.css';
import FlipClock from './components/FlipClock/FlipClock'
import MFooter from './components/MFooter/MFooter'

function App() {
  return (
    <div>
      <div class="tabview">
      <header class="site-header" role="banner">
      <div class="site-top">
        <div class="site-branding">
          <span class="site-title">
            <span class="logolink moe-mashiro">
              <div class="TitleBox" onClick={() => { window.location.href = "http://navigation.ifengfeng.cn/" }}>
                <a>
                  <span class="sakurasono">i</span>
                  <span class="shironeko">饺子</span>
                </a>
              </div>
            </span>
          </span>
        </div>
      </div>
      </header>
      </div>
    
    <div className="App">
      
      <div className="main">
        <FlipClock />
        <div className="typingbox">
          <div className="typinganimation">你好！这里什么都没有！</div>
        </div>

        <div className="Footer">
          <MFooter />
          <p><a target="_blank" class="beian" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备17055874号-1</a></p>
          <p><a target="_blank" class="beian" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33102102331420" >浙公网安备 33102102331420号</a></p>
          <p>© 2024 Ifengfeng. All rights reserved.</p>
          
        </div>
      </div>
    </div>
    </div>
  );
}

export default App;

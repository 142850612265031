
import React, { useEffect, useState } from 'react';
import './MFooter.css';
import axios from 'axios';



// 脚手架示例组件
function MFooter() {

  const [mdata,setMdata] = useState("「 我们把世界看错，反说它欺骗了我们。 」");

   useEffect(() => {
      axios.get('https://international.v1.hitokoto.cn/?c=d&c=h&c=i&c=k&encode=json/')
      .then(response => {
        setMdata("「 " + response.data.hitokoto + "」")
      }
      ).catch(error => {
        setMdata("「 我们把世界看错，反说它欺骗了我们。 」")
      })
      
  }, []);

    return (
        <div className="MFooter">
            <p>{mdata}</p>
        </div>
    )
};

export default MFooter;
